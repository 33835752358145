export default {
  home_intro_heading: "Solutions for the electricity market",
  home_intro_description:
    "RION Energie Sarl - The independent partner for your successful energy trading",
  products_and_services: "Products and services",
  hfpc_title_products: "HPFC Electricity Hourly Price Forward Curve",
  hfpc_subtitle: "Market areas Switzerland and Europe",
  portfolio_management_title:
    "Digital power portfolio with algo-trading support",
  profile_rol_out_title: "Profile roll-out tool",
  profile_rol_out_subtitle:
    "Would you like to estimate your load profile for the coming years in a few clicks?",
  rion_energe_gmbh_text:
    "We are a young start-up from the canton of Zürich whose objective is to develop efficient digital solutions for the energy sector. In recent months, digitalization has experienced a significant boom. We are convinced that effective digital solutions are a major asset for the success of tomorrow's businesses and we want to help develop them. Our goal is to offer simple, fast and effective tools. For their development, we rely on our experience in energy supply for one of the largest consumers in Switzerland and on our IT expertise developed in particular during the implementation of numerous analysis models for various sectors. We look forward to meeting you.",
  about_us: "About us",
  contact: "Contact",
  login_btn: "Log in",
  offer_btn: "Our products and services",
  profile_rol_out_text:
    "Quick, flexible and intuitive, our online tool allows you to estimate your load curve for the coming years from one or more known historical profiles. Load one or more historical load curves, enter the target year and any known variations, and our online tool will display your new profile estimate and its main characteristics in a few seconds.",
  contact_us_btn: "Contact us",
  profile_rol_out_benefit_first:
    "Choice of region (impact for public holidays)",
  profile_rol_out_benefit_second:
    "Unroll many profiles at the same time and automatically cumulate them if necessary",
  profile_rol_out_benefit_third: "Apply a ratio to the scrolled profile",
  profile_rol_out_benefit_fourth:
    "Possibility to load mixed historical profiles from different years",
  profile_rol_out_benefit_fifth: "Export in .csv format (Excel)",
  interest_content_title: "Have we awakened your interest?",
  interest_content_subtitle:
    "Then fill out the contact form or send us an email. We would be pleased to present our tools to you",
  contacts_title: "Contact",
  contacts_name: "First Name",
  contacts_last_name: "Last Name",
  contacts_company: "Company",
  contacts_phone: "Phone",
  contacts_email: "E-mail",
  contacts_interested_in: "I'm interested in",
  contacts_message: "Your Message",
  contacts_person: "Your contact person",
  send_btn_text: "Send",
  privacy_title: "Privacy policy",
  imprint_title: "Imprint",
  digital_title: "Digital power portfolio with algo-trading support",
  digital_subtitle:
    "Manage your electricity portfolio in a flash with our online platform and beat the market with our algo-trading script",
  digital_platform_text:
    "Our digital platform allows you to manage your electricity portfolio quickly and easily. The integrated tools in the cockpit determine the characteristics of your profile, calculate the theoretical hedge according to the method you choose and graphically show you the positions you have taken",
  market_title: "HPFC for the Swiss electricity market",
  market_subtitle:
    "For power market players we offer our arbitrage-free HPFC, which is based on EEX futures settlement prices. The complex algorithm provides a reliable and repetitive method that learns from historical spot prices and takes seasonality into account. Our algorithm is continuously optimized and we participate in HPFC benchmarks.",
  portfoli_management_benefit_first: "Long term strategie (3 years)",
  portfoli_management_benefit_second:
    "Individually defined parameters and risks",
  portfoli_management_benefit_third:
    "Trading algorithm historically beats the market",
  portfoli_management_benefit_fourth:
    "Safety mechanisms in case of highly volatile markets",
  portfoli_management_benefit_fifth:
    "Adjustments with quarterly and monthly products",
  portfoli_management_benefit_sixt:
    "Full daily notification of the trading algorithm",
  chart_title: "Trading in progress",
  buy_average: "Buy average",
  market: "Market",
  sell_average: "Sell average",
  hpfc_title: "HPFC Electricity",
  hpfc_subtitle: "Hourly Price Forward Curve",
  hpfc_description:
    "Are you a power producer, trader or large consumer looking for a reliable price curve? Try our HPFC free of charge and convince yourself.",
  market_risks_title: "Beat the market while controlling the risks!",
  market_risks_description:
    "Because it is sometimes difficult to make rational decisions in a turbulent market and beating the market is not easy, we have developed an algo trading script that beats the market since years and gives you a complete daily opinion on all the positions of your electric portfolio. Whether you are long or short, consumer, supplier, producer or trader, our algo trading script adapts to your current situation and advises you daily while respecting a supply/sell corridor whose parameters you control.",
  interested_content_title: "30-day free trial",
  interested_content_description:
    "Have we awakened your interest? Then fill out the contact form or send us an email. We would be pleased to present our tools to you",
  interested_content_description_without_interest_sentence:
    "Then fill out the contact form or send us an email. We would be pleased to present our tools to you",
  chart_performance_consumer_title:
    "Performance of the Algo-trading script: Consumer role",
  chart_performance_producer_title:
    "Performances of the Algo-trading script: Producer role",
  chart_performance_consumer_description:
    "Energy volume: 500 GWh - Simulation for the procurement of an annual base product results in an advantage over the market average of CHF 16.8 million over the last 10 years.",
  chart_performance_producer_description:
    "Energy volume: 500 GWh - Simulation for the sale of an annual base product results in an advantage over the market average of CHF 9.5 million over the last 10 years.",
  hpfc_product_title: "HPFC Electricity",
  hpfc_product_subtitle: "Hourly price Forward Curves",
  hpfc_product_description:
    "Are you an electricity producer, trader or large consumer looking for a reliable price curve? Try our HPFC for free and let yourself be convinced",
  portfolio_product_title: "Digital power portfolio",
  portfolio_product_subtitle: "with algo-trading support",
  portfolio_product_description:
    "Manage your electricity portfolio in a flash with our online platform and beat the market with our algo-trading script",
  profile_rol_out_product_title: "Profile roll-out",
  profile_rol_out_product_description:
    "Would you like to estimate your load profile for the coming years in a few clicks?",
  strategy_consulting_title: "Projects and consulting",
  strategy_consulting_description:
    "Would you like advice from an energy specialist? Would you like to implement an idea or a project, but don't have the resources to make it happen? We support you in all topics related to energy procurement/sales, tenders, strategy consulting, portfolio management, IT development or process optimization - from simple tasks to project management.",
  hpfc_benefit_first: "Daily delivery via FTP or e-mail",
  hpfc_benefit_second: "Daily calibration in hourly raster",
  hpfc_benefit_third: "Market areas Switzerland and Europe",
  hpfc_benefit_fourth: "30-day free trial",
  hpfc_benefit_fifth: "Independent provider",
  hpfc_benefit_sixt:
    "Access to web platform with marketdata cockpit (Except test version)",
  managing_director: "Managing Director",
  captcha_error: "Captcha error has occurred. Please, solve captcha correctly",
  data_protection_title: "Data protection",
  data_protection_p1:
    "We are very pleased that you are interested in our company. Data protection is of a particularly high priority for the Management of RION Energie GmbH. A use of the websites of RION Energie GmbH is basically without any information personal data possible. If a data subject special services of our company via our website would like to take, however, a processing of personal data become necessary. Is the processing of personal data required and there is no legal basis for such processing As a basis, we generally obtain consent from the data subject.",
  data_protection_p2:
    "The processing of personal data, for example the name, the address, e-mail address or telephone number of a data subject, is always carried out in accordance with the General Data Protection Regulation and in Correspondence with those applicable to RION Energie GmbH country-specific data protection regulations. By means of this Privacy Policy our company would like the public about Type, scope and purpose of the data collected, used and processed by us inform personal data. Furthermore, data subjects by means of this data protection declaration about the rights to which you are entitled enlightened.",
  data_protection_p3:
    "RION Energie GmbH is responsible for processing numerous technical and organizational measures implemented to as complete protection as possible of the data processed via this website secure personal data. Nevertheless, Internet-based Data transmissions always have security gaps, so that a absolute protection cannot be guaranteed. For this reason stands every data subject is free to access personal data as well alternative ways, for example by telephone, to us.",
  data_protection_p4:
    "This privacy policy was created by the Data protection declaration generator of the DGD Deutsche Gesellschaft für Datenschutz GmbH, which carries out data protection audits, in cooperation with created by the media law firm WILDE BEUGER SOLMECKE.",
  privacy_content_title_1: "1. Définitions?",
  privacy_main_content_1_p1:
    "The data protection declaration of RION Energie GmbH is based on the Terms used by the European Directive and Regulatory body when issuing the General Data Protection Regulation (GDPR) were used. Our privacy policy aims to both for the public as well as for our customers and business partners must be easy to read and understand. To this guarantee, we would like to explain the terminology used in advance explain.",
  privacy_main_content_1_p2:
    "In this data protection declaration, we use, among other things, the following terms:",
  privacy_additional_1_title: "personal data",
  privacy_additional_1_p1:
    "Personal data is any information relating to a identified or identifiable natural person (hereinafter 'data subject'). One becomes identifiable natural person considered, directly or indirectly, in particular by assignment to an identifier such as a name, to a identification number, to location data, to an online identifier or to a or several special characteristics that express the physical, physiological, genetic, psychological, economic, cultural or social identity of this natural person, can be identified.",
  privacy_content_title_2: "data subject",
  privacy_main_content_2_p1:
    "Data subject is any identified or identifiable person natural person whose personal data is processed by the processed by those responsible for processing.",
  privacy_content_title_3: "Processing",
  privacy_main_content_3_p1:
    "Processing is any with or without the help of automated procedures performed operation or any such series of operations in connection with personal data such as collecting, capturing, the organizing, arranging, storing, adapting or change, the reading, the queries, the use, the Disclosure by transmission, dissemination or any other form of providing, matching or linking, the restriction, deletion or destruction.",
  privacy_content_title_4: "Restriction of processing",
  privacy_main_content_4_p1:
    "Restriction of processing is the marking of stored personal data with the aim of restricting their future processing.",
  privacy_content_title_5: "Profiling",
  privacy_main_content_5_p1:
    "Profiling is any type of automated processing personal data, which consists in the fact that this personal information is used to provide certain personal information to evaluate aspects relating to a natural person, in particular, aspects related to work performance, more economical location, health, personal preferences, interests, Reliability, conduct, whereabouts or relocation of these analyze or predict a natural person.",
  privacy_content_title_6: "Pseudonymization",
  privacy_main_content_6_p1:
    "Pseudonymization is the processing of personal data in a way in which the personal data without Adding additional information is no longer one can be assigned to a specific data subject, provided that this additional information is kept separately and subject to technical and organizational measures that ensure that the personal data is not one assigned to an identified or identifiable natural person will.",
  privacy_content_title_7: "Responsible or responsible for processing",
  privacy_main_content_7_p1:
    "Responsible or responsible for the processing is the natural or legal person, public authority, institution or other Body, alone or together with others on the purposes and means of processing personal data. Are the purposes and means of this processing governed by Union law or the law of the Member States, the Those responsible respectively can use the specific criteria its designation according to Union law or the law of Member States are provided.",
  privacy_content_title_8: "Processor",
  privacy_main_content_8_p1:
    "Processor is a natural or legal person Authority, institution or other body that collects personal data processed on behalf of the person responsible.",
  privacy_content_title_9: "Recipient",
  privacy_main_content_9_p1:
    "Recipient is a natural or legal person, authority, establishment or other body of personal data be disclosed, regardless of whether it is one third party acts or not. Authorities working under a particular Investigation order under Union law or the law of Member States may receive personal data however, do not count as recipients.",
  privacy_content_title_10: "Third Party",
  privacy_main_content_10_p1:
    "Third party is a natural or legal person, authority, Institution or other body other than the person concerned, the Responsible, the processor and the persons who under the direct responsibility of the person responsible or the Processors are authorized to process the personal data to process.",
  privacy_content_title_11: "Consent",
  privacy_main_content_11_p1:
    "Consent is given voluntarily by the data subject specific case in an informed manner and unequivocally declaration of intent made in the form of a declaration or a other clear confirmatory action with which the affected Person indicates that they will process the processing of them relevant personal data agrees.",
  privacy_content_title_12:
    "2. Name and address of the person responsible for processing",
  privacy_main_content_12_p1:
    "Responsible within the meaning of the General Data Protection Regulation, others applicable in the member states of the European Union data protection laws and other regulations data protection character is:",
  website: "Website",
  privacy_content_title_13: "3. Cookies",
  privacy_main_content_13_p1:
    "The Internet pages of the RION Energie GmbH use cookies. cookies are text files that are stored in an internet browser on a Computer system are filed and stored.",
  privacy_main_content_13_p2:
    "Numerous websites and servers use cookies. Many Cookies contain a so-called cookie ID. A cookie ID is one unique identifier of the cookie. It consists of a string through which websites and servers the specific internet browser can be assigned in which the cookie was stored. This enables the visited websites and servers to individual browser of the data subject from others Internet browsers that contain other cookies. A specific internet browser can use the unique cookie ID be recognized and identified.",
  privacy_main_content_13_p3:
    "Through the use of cookies, RION Energie GmbH can offer users provide more user-friendly services on this website would not be possible without the setting of cookies.",
  privacy_main_content_13_p4:
    "By means of a cookie, the information and offers can be accessed our website can be optimized with the user in mind. As previously mentioned, cookies enable us to enable users of our to recognize the website. The purpose of this recognition is to make it easier for users to use our website. Of the Users of a website that uses cookies must For example, not his again every time you visit the website Enter access data because this is from the website and the on cookie stored on the user's computer system. Another example is the cookie of a shopping cart Online shop. The online store remembers the items that a customer placed in the virtual shopping cart via a cookie.",
  privacy_main_content_13_p5:
    "The person concerned can prevent the setting of cookies by our website at any time by means of a corresponding setting of the prevent the Internet browser used and thus the setting of Object to cookies permanently. Furthermore, already set Cookies at any time through an internet browser or other software programs are deleted. This is common in all Internet browsers possible. The data subject deactivates the Setting of cookies in the Internet browser used are under Under certain circumstances, not all functions of our website are fully functional usable.",
  privacy_content_title_14: "4. Collection of general data and information",
  privacy_main_content_14_p1:
    "The website of RION Energie GmbH records each time the Website by a data subject or an automated System a set of general data and information. This General data and information are saved in the log files of the saved on the server. The (1) used can be recorded Browser types and versions, (2) that of the accessing system operating system used, (3) the website from which a accessing system reaches our website (so-called referrer), (4) the sub-websites, which have an accessing system on our website, (5) the date and the time of access to the website, (6) a Internet protocol address (IP address), (7) the Internet service provider of the accessing system and (8) others similar data and information relevant to security in the event of attacks on our information technology systems.",
  privacy_main_content_14_p2:
    "When using these general data and information, the RION Energie GmbH does not draw any conclusions about the data subject. Rather, this information is needed to (1) deliver the content to deliver our website correctly, (2) the contents of our to optimize the website and the advertising for it, (3) the permanent functionality of our information technology to ensure systems and the technology of our website as well as (4) to law enforcement authorities in the event of a cyber attack provide information necessary for law enforcement. This Data and information collected anonymously are processed by RION Energie GmbH therefore on the one hand statistically and also with the goal evaluated, data protection and data security in our Increase in order to ultimately provide an optimal level of protection for companies secure the personal data we process. The anonymous data of the server log files are separated from all personal data provided by a data subject saved.",
  privacy_content_title_15: "5. Contact option via the website",
  privacy_main_content_15_p1:
    "The website of RION Energie GmbH contains due to Statutory regulations information that fast electronic Contacting our company as well as an immediate Enable communication with us, which is also a general address of the so-called electronic mail (e-mail address). If a data subject via e-mail or via a contact form contacts the person responsible for processing, are the personal data transmitted by the data subject Data saved automatically. Such on a voluntary basis by a data subject to the data controller Personal data transmitted will be used for the purposes of Processing or contacting the data subject saved. There is no transfer of this personal data data to third parties.",
  privacy_content_title_16: "6. Routine Deletion and Blocking of Personal Data",
  privacy_main_content_16_p1:
    "The controller processes and stores personal data of the data subject only for the period which is necessary to achieve the purpose of storage or if this is done by the European directive and regulation giver or any other legislator in laws or regulations, to which the controller is subject, was provided.",
  privacy_main_content_16_p2:
    "If there is no storage purpose or if one is running from the European Guideline and regulation giver or another competent Legislatively prescribed storage period, the personal data routinely and in accordance with the legal regulations blocked or deleted.",
  privacy_content_title_17: "7. Rights of the data subject",
  privacy_main_content_17_p1: "Right to confirmation",
  privacy_main_content_17_p2:
    "Each data subject has the right from the European Directive and Right granted by the regulator for the processing to request confirmation from those responsible as to whether they relevant personal data are processed. want one affected person can make use of this right of confirmation you can contact an employee of the for the at any time contact the person responsible for processing.",
  privacy_content_title_18: "Right to information",
  privacy_main_content_18_p1:
    "Any person affected by the processing of personal data has the granted by the European directive and regulation giver right, at any time by the controller free information about his person stored personal data and a copy of this information. Furthermore, the European legislator for directives and regulations data subject is granted access to the following information:",
  privacy_main_content_18_li1: "the processing purposes",
  privacy_main_content_18_li2:
    "the categories of personal data being processed",
  privacy_main_content_18_li3:
    "the recipients or categories of recipients to whom the personal data has been or will be disclosed be disclosed, especially to recipients in third countries or at international organizations",
  privacy_main_content_18_li4:
    "if possible, the planned duration for which the personal data is stored or, if this is not possible, the Criteria for determining this duration",
  privacy_main_content_18_li5:
    "the existence of a right to rectification or erasure of them personal data concerned or to restriction of Processing by the controller or one Right to object to this processing",
  privacy_main_content_18_li6:
    "the existence of a right of appeal to a supervisory authority",
  privacy_main_content_18_li7:
    "if the personal data are not with the data subject collected: All available information about the origin the data",
  privacy_main_content_18_li8:
    "the existence of automated decision-making including profiling in accordance with Article 22 (1) and (4) GDPR and — at least in these cases — meaningful information about the logic involved, as well as the scope and intended Effects of such processing on the data subject person",
  privacy_main_content_18_p2:
    "Furthermore, the data subject has a right to information about whether personal data to a third country or to a international organization have been submitted. If this is the case otherwise, the data subject has the right Information about the appropriate guarantees in connection with the to receive transmission.",
  privacy_main_content_18_p3:
    "If a data subject wishes to exercise this right to information take, she can at any time contact an employee of the for contact the controller.",
  privacy_content_title_19: "Right to Rectification",
  privacy_main_content_19_p1:
    "Any person affected by the processing of personal data has the granted by the European directive and regulation giver right, the immediate correction of incorrect information concerning you to request personal data. Furthermore, the person concerned person the right to, taking into account the purposes of Processing, completion of incomplete personal data - also by means of a supplementary declaration - to demand.",
  privacy_main_content_19_p2:
    "If a data subject wishes to exercise this right to rectification take, she can at any time contact an employee of the for contact the controller.",
  privacy_content_title_20: "Right to erasure (right to be forgotten)",
  privacy_main_content_20_p1:
    "Any person affected by the processing of personal data has the granted by the European directive and regulation giver Right to require the person responsible to relevant personal data will be deleted immediately, if one of the following reasons applies and to the extent that the Processing is not required:",
  privacy_main_content_20_p2:
    "The personal data were collected for such purposes or processed in any other way for which they are no longer processed are necessary.",
  privacy_main_content_20_p3:
    "The data subject revokes their consent to which the processing according to Art. 6 Para. 1 Letter a DS-GVO or Art. 9 para. 2 letter a DS-GVO and there is no one other legal basis for processing.",
  privacy_main_content_20_p4:
    "The data subject files an objection in accordance with Art. 21 (1) GDPR against the processing and there are no overriding ones legitimate reasons for the processing, or the data subject According to Art. 21 Para. 2 DS-GVO, the person objects to the processing on.",
  privacy_main_content_20_p5:
    "The personal data have been unlawfully processed.",
  privacy_main_content_20_p6:
    "The deletion of the personal data is necessary to fulfill a legal obligation under Union law or the law of Member States required to which the controller is subject.",
  privacy_main_content_20_p7:
    "The personal data were collected in relation to the services offered of the information society in accordance with Art. 8 Para. 1 DS-GVO.",
  privacy_main_content_20_p8:
    "If one of the above reasons applies and an affected person the deletion of personal data stored at RION Energie GmbH are stored, she can at any time to an employee of the for processing Contact responsible person. The employee of RION Energie GmbH will arrange for the request for deletion to be complied with immediately.",
  privacy_main_content_20_p9:
    "Were the personal data collected by RION Energie GmbH made public and is our company as the controller according to Art. 17 Para. 1 DS-GVO for the deletion of personal data obliged, RION Energie GmbH takes into account the available technology and implementation costs appropriate measures, including technical ones, to ensure others for the Data processing responsible for the published process personal data, to inform that the data subject from these others for the Data processing responsible for the deletion of all links this personal data or from copies or replications has requested this personal data, insofar as the processing is not required. The employee of RION Energie GmbH will arrange what is necessary in individual cases.",
  privacy_content_title_21: "Right to restriction of processing",
  privacy_main_content_21_p1:
    "Any person affected by the processing of personal data has the granted by the European directive and regulation giver Right to obtain restriction of processing from the person responsible demand if one of the following conditions is met:",
  privacy_main_content_21_li1:
    "The accuracy of the personal data is verified by the data subject denied, for a period that allows the The person responsible enables the accuracy of the personal data to check data.",
  privacy_main_content_21_li2:
    "The processing is unlawful, the data subject opposes the Deletion of the personal data and requests instead restricting the use of personal data.",
  privacy_main_content_21_li3:
    "The person responsible needs the personal data for the purposes of processing no longer, the data subject however, requires it for assertion, exercise or defense of legal claims.",
  privacy_main_content_21_li4:
    "The data subject has objected to the processing pursuant to Art. Art. 21 Para. 1 DS-GVO filed and it is not yet clear whether the legitimate reasons of the person responsible compared to those of the data subject prevail.",
  privacy_content_title_22: "Right to data portability",
  privacy_main_content_22_p1:
    "Any person affected by the processing of personal data has the granted by the European directive and regulation giver Right, the personal data concerning you, which through the data subject has been made available to a person responsible, in a structured, commonly used and machine-readable format receive. You also have the right to transfer this data to someone else Responsible without hindrance by the person responsible, the to transmit the personal data provided, if the processing is based on the consent in accordance with Art. 6 Para. 1 letter a DS-GVO or Art. 9 para. 2 letter a DS-GVO or on a contract according to Art. 6 Para. 1 Letter b DS-GVO and the Processing is carried out using automated procedures, provided that the Processing not required for the performance of a task is in the public interest or is being exercised public authority, which is transferred to the person responsible became.",
  privacy_main_content_22_p2:
    "Furthermore, when exercising their right, the data subject has Data transferability according to Art. 20 Para. 1 DS-GVO the right to obtain that the personal data directly from a responsible to another responsible person as far as this is technically feasible and insofar as this is not the case the rights and freedoms of other people are impaired.",
  privacy_main_content_22_p3:
    "To assert the right to data portability, affected person at any time to an employee of RION Energie contact GmbH.",
  privacy_content_title_23: "Real contradiction",
  privacy_main_content_23_p1:
    "Any person affected by the processing of personal data has the granted by the European directive and regulation giver Right, for reasons that arise from your particular situation, against the processing of personal data concerning you at any time Data based on Art. 6 Para. 1 Letter e or f DS-GVO takes place to lodge an objection. This also applies to one on this Policy-Based Profiling.",
  privacy_main_content_23_p2:
    "RION Energie GmbH processes the personal data in Case of contradiction no longer unless we can compelling prove legitimate grounds for processing that Interests, rights and freedoms of the data subject prevail, or the processing serves to assert or exercise or defense of legal claims.",
  privacy_main_content_23_p3:
    "Does RION Energie GmbH process personal data in order to to operate direct advertising, the data subject has the right You can object to the processing of your personal data at any time Submit data for the purpose of such advertising. This also applies to profiling to the extent it is associated with such direct marketing stands. If the data subject objects to RION Energie GmbH processing for direct marketing purposes, RION Energie GmbH no longer uses the personal data for these purposes to process.",
  privacy_main_content_23_p4:
    "In addition, the data subject has the right, for reasons arising from resulting from their particular situation, against that concerning them Processing of personal data at RION Energie GmbH for scientific or historical research purposes or to statistical purposes according to Art. 89 Para. 1 DS-GVO, to object, unless such processing is necessary to fulfill a task in the public interest necessary.",
  privacy_main_content_23_p5:
    "In order to exercise the right to object, the person concerned can person directly to every employee of RION Energie GmbH or one contact other employees. The data subject is further away freely, in connection with the use of services of Information society, notwithstanding Directive 2002/58/EC, her to exercise the right of objection by means of automated procedures which technical specifications are used.",
  privacy_content_title_24:
    "Automated decisions in individual cases including profiling",
  privacy_main_content_24_p1:
    "Any person affected by the processing of personal data has the granted by the European directive and regulation giver Right, not one based solely on an automated Processing - including profiling - based decision to be subjected to its legal effect develops or significantly affects them in a similar way, provided that the decision (1) is not for the conclusion or the Performance of a contract between the data subject and the controller is required, or (2) due to Union or Member State legislation to which the Responsible subject is permitted and this Legislation appropriate measures to protect the rights and freedoms and legitimate interests of the data subject included or (3) with the express consent of those affected person takes place.",
  privacy_main_content_24_p2:
    "Is the decision (1) for the conclusion or fulfillment of a Contract between the data subject and the controller necessary or (2) it takes place with express consent of the data subject, RION Energie GmbH shall take appropriate measures Measures to protect the rights and freedoms as well as those legitimate Protecting the interests of the data subject, for which at least that Right to obtain human intervention on the part of the those responsible, on the presentation of one's own point of view and on challenge the decision.",
  privacy_main_content_24_p3:
    "If the data subject wishes to have rights relating to automated You can contact us at any time to assert decisions contact an employee of the controller.",
  privacy_content_title_25: "Right to revoke a data protection law consent",
  privacy_main_content_25_p1:
    "Any person affected by the processing of personal data has the granted by the European directive and regulation giver Right to consent to the processing of personal data to revoke at any time.",
  privacy_main_content_25_p2:
    "If the data subject wishes to exercise their right to revoke a You can assert your consent at any time by contacting a Contact employees of the person responsible for processing.",
  privacy_content_title_26: "8. Legal basis of processing",
  privacy_main_content_26_p1:
    "Art. 6 I lit. a GDPR serves as the legal basis for our company for processing operations in which we have consent for a obtain a specific processing purpose. Is processing personal data to fulfill a contract whose Party to the data subject is required, like this This is the case, for example, with processing operations that are carried out for a delivery of goods or the provision of any other service or Consideration is necessary, the processing is based on Art. 6 I lit. b GDPR. The same applies to such processing operations Implementation of pre-contractual measures are required, e.g Cases of inquiries about our products or services. subject our company a legal obligation by which a Processing of personal data is required, such as for example to fulfill tax obligations, the Processing based on Art. 6 I lit. c GDPR. In rare cases, the Processing of personal data is required to vital interests of the data subject or another to protect a natural person. This would be the case, for example if a visitor would be injured in our company and then his name, his age, his health insurance data or other vital information to a doctor, hospital or other third parties would have to be passed on. Then she would Processing based on Art. 6 I lit. d GDPR. Ultimately could Processing operations are based on Art. 6 I lit. f GDPR. On this The legal basis is based on processing operations that are not carried out by any of the The aforementioned legal bases are recorded when the processing to protect a legitimate interest of our company or of a third party is required, provided that the interests, fundamental rights and fundamental freedoms of the person concerned do not prevail. Such Processing operations are permitted to us in particular because they have been specifically mentioned by the European legislator. He took the view that a legitimate interest could be assumed if the data subject is a customer of Responsible is (recital 47 sentence 2 DS-GVO).",
  privacy_content_title_27:
    "9. Legitimate interests in processing pursued by the controller or a third party",
  privacy_main_content_27_p1:
    "Is the processing of personal data based on Article 6 I lit. f DS-GVO is our legitimate interest in carrying out our Doing business for the benefit of all our employees and our shareholders.",
  privacy_content_title_28:
    "10. Duration for which the personal data will be stored",
  privacy_main_content_28_p1:
    "The criterion for the duration of the storage of personal Data is the respective statutory retention period. After expiration After the deadline, the corresponding data will be routinely deleted, provided they are no longer used to fulfill or initiate a contract required are.",
  privacy_content_title_29:
    "11. Statutory or contractual requirements for providing the personal data; Necessity for the conclusion of the contract; obligation of the data subject to provide the personal data; possible consequences of non-provision",
  privacy_main_content_29_p1:
    "We clarify that the provision of personal Data is partly required by law (e.g. tax regulations) or from contractual regulations (e.g. information on contractual partner) can arise. Sometimes it can become one conclusion of the contract, it may be necessary for a data subject to contact us provides personal data that are subsequently carried out have to be processed by us. The person concerned is for example, obliges us to provide personal data provide when our company has a contract with you completes. Failure to provide the personal data would have result in the contract not being concluded with the person concerned could become. Before providing personal data by the person concerned must contact one of our employees turn around. Our employee clarifies the person concerned on a case-by-case basis on whether the provision of the personal data required by law or contract or for the Conclusion of the contract is required whether there is an obligation that to provide personal data and what the consequences are non-provision of the personal data.",
  privacy_content_title_30: "12. Existence of automated decision-making",
  privacy_main_content_30_p1:
    "As a responsible company, we do without one automatic decision-making or profiling.",
  hpfc_electricity_text:
    "An electricity HPFC shows the future development of electricity prices in a price curve. The more accurate it is, the more reliably an electricity portfolio can be valued.",
  learn_more_btn: "Learn more",
  profile_roll_out_content_title:
    "Need a specific option? We customize it for you!",
  profile_roll_out_content_description:
    "Our tools have been developped, worked with a wide range of input formats and allows you to perform standard operations which are commonly needed. Please take contact with us if you miss some possibilities and wish some complementary options. We are looking forward to understand your specifications and provide you a suitable and efficient tool matching your needs.",
  hfpc_content_title: "HPFC for the Swiss electricity market",
  hfpc_content_description:
    "For power market players we offer our arbitrage-free HPFC, which is based on EEX futures settlement prices. The complex algorithm provides a reliable and repetitive method that learns from historical spot prices and takes seasonality into account. Our algorithm is continuously optimized and we participate in HPFC benchmarks.",
  profile_roll_out_content_title:
    "Need a specific option? We customize it for you!",
  profile_roll_out_content_description:
    "Our tools have been developped, worked with a wide range of input formats and allows you to perform standard operations which are commonly needed. Please take contact with us if you miss some possibilities and wish some complementary options. We are looking forward to understand your specifications and provide you a suitable and efficient tool matching your needs.",
  hfpc_content_title: "HPFC for the Swiss electricity market",
  hfpc_content_description:
    "For power market players we offer our arbitrage-free HPFC, which is based on EEX futures settlement prices. The complex algorithm provides a reliable and repetitive method that learns from historical spot prices and takes seasonality into account. Our algorithm is continuously optimized and we participate in HPFC benchmarks.",
  what_we_offer_btn: "What we offer",
  performance_subtitle:
    "Performance realized with trading data over a period of three years before the delivery year. All units in cts/kWh",
  general_title: "RION Energie GmbH | Solutions for the electricity market",
  site_meta_title: "RION Energie GmbH",
  site_meta_description:
    "RION Energie GmbH - For players in the electricity market, we offer our arbitrage-free HPFC, which is based on EEX futures settlement prices.",
  hpfcelEctricity_page_title:
    "Electricity HPFC - Hourly Price Forward Curve | RION Energie GmbH",
  digital_page_title:
    "Digital power portfolio with algo-trading support | RION Energie GmbH",
  profile_rol_out_page_title: "Profile roll-out tool | RION Energie GmbH",
  general_description:
    "RION Energie Sarl - The independent partner for your successful energy trading",
  hpfcelEctricity_page_description:
    "For players in the electricity market, we offer our arbitrage-free HPFC, which is based on EEX futures settlement prices",
  digital_page_description:
    "Manage your electricity portfolio in a flash with our online platform and beat the market with our algo-trading script",
  profile_rol_out_page_description:
    "Would you like to estimate your load profile for the coming years in a few clicks?",
  schema_description:
    "Provision of services in the field of energy supply and trade in various products related to the aforementioned field.",
  schema_address_country: "Switzerland",
  schema_contact_type: "customer service",
};
