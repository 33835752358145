import Swal, { SweetAlertIcon, SweetAlertResult } from "sweetalert2";
import en from "../public/locales/en.js";
import de from "../public/locales/de.js";
import fr from "../public/locales/fr.js";

interface IShowDialogProps {
  title?: string;
  icon?: SweetAlertIcon;
  html?: string | HTMLElement | JQuery | undefined;
  showCloseButton?: boolean;
  showCancelButton?: boolean;
  focusConfirm?: boolean;
  confirmButtonText?: string;
  cancelButtonText?: string;
  text?: string;
  confirmButtonColor?: string;
  showConfirmButton?: boolean;
}

export const showDialog = ({
  title,
  icon,
  text,
  html,
  showCloseButton = true,
  showCancelButton = true,
  focusConfirm,
  confirmButtonText = "OK",
  cancelButtonText = "CANCEL",
  showConfirmButton = true,
  confirmButtonColor,
}: IShowDialogProps): Promise<SweetAlertResult<any>> => {
  return Swal.fire({
    title,
    text,
    icon,
    html,
    showCloseButton,
    showCancelButton,
    showConfirmButton,
    focusConfirm,
    confirmButtonText,
    cancelButtonText,
    confirmButtonColor,
    customClass: "alert",
  });
};

export const translate = (locale: "en" | "de" | "fr" | string, key: string) => {
  const translates = {
    en,
    de,
    fr,
  };

  return translates[locale][key] ? translates[locale][key] : "????";
};
