import React, { FC, useMemo } from "react";
import { Organization, Thing, WithContext } from "schema-dts";
import { AppProps } from "next/app";
import Head from "next/head";

import "../styles/scss/main.scss";
import "../styles/globals.css";
import { translate } from "../utils";
import { useRouter } from "next/router";

const WrappedApp: FC<AppProps> = ({ Component, pageProps }): JSX.Element => {
  const { locale, pathname } = useRouter();

  const renderTitleByUrl = useMemo(() => {
    const currentPath = pathname.slice(1);

    if (!currentPath) {
      return translate(locale, "general_title");
    }

    const result = {
      hpfcelEctricity: translate(locale, "hpfcelEctricity_page_title"),
      imprint: translate(locale, "imprint_title"),
      portfolioManagement: translate(locale, "digital_page_title"),
      privacy: translate(locale, "privacy_title"),
      profileRollOut: translate(locale, "profile_rol_out_page_title"),
    };

    return result[currentPath];
  }, [locale, pathname]);

  const renderDescriptionByUrl = useMemo(() => {
    const currentPath = pathname.slice(1);

    if (!currentPath) {
      return translate(locale, "general_description");
    }

    const result = {
      hpfcelEctricity: translate(locale, "hpfcelEctricity_page_description"),
      imprint: translate(locale, "imprint_title"),
      portfolioManagement: translate(locale, "digital_page_description"),
      privacy: translate(locale, "privacy_title"),
      profileRollOut: translate(locale, "profile_rol_out_page_description"),
    };

    return result[currentPath];
  }, [locale, pathname]);

  const canonicalUrl = useMemo(
    () =>
      pathname === "/"
        ? `https://rion-energie.ch${pathname}`
        : `https://rion-energie.ch/${locale}${pathname}`,
    [pathname, locale]
  );

  const JsonLd = <T extends Thing>(json: WithContext<T>) => (
    <script type="application/ld+json">{JSON.stringify(json)}</script>
  );

  return (
    <>
      <Head>
        <title>{translate(locale, "general_title")}</title>
        <link rel="icon" href="favicon.ico" />
        <link
          rel="alternate"
          hrefLang={locale}
          href={`https://rion-energie.ch/${locale}${pathname}`}
        />
        <meta charSet="utf-8" />
        <meta property="og:locale" content={locale} />
        <meta httpEquiv="Content-Language" content="en,fr,de" />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="title"
          property="og:title"
          content={renderTitleByUrl}
          lang={locale}
        />

        <meta
          name="description"
          property="og:description"
          content={renderDescriptionByUrl}
          lang={locale}
        />
        <meta property="og:site_name" content="Rion Energie" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.rion-energie.ch" />
        <link rel="canonical" href={canonicalUrl} />
        {JsonLd<Organization>(
          // prettier-ignore
          {
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "RION Energie GmbH",
            "url": `https://rion-energie.ch/${locale}/`,
            "logo": "https://rion-energie.ch/wp-content/uploads/2019/11/cropped-logo_rion_energie.png",
            "description": translate(locale, "schema_description"),
            "address": {
              "@type": "PostalAddress",
              "streetAddress": "Heimstrasse 12",
              "addressLocality": "Dietikon",
              "postalCode": "8953",
              "addressCountry": translate(locale, "schema_address_country")
            },
            "contactPoint": {
              "@type": "ContactPoint",
              "telephone": "+41 (0)44 586 15 06",
              "contactType": translate(locale, "schema_contact_type")
            }
          }
        )}
      </Head>
      <Component {...pageProps} />
    </>
  );
};

export default WrappedApp;
